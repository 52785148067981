<template>
  <div class="debts">
    <v-card class="mb-2">
      <v-card-title> Laporan Utang </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-select
          v-model="filter.show"
          :items="filter.showList"
          label="Tampilkan"
          outlined
        ></v-select>

        <date-range-picker v-model="filter.dateRange"></date-range-picker>
      </v-card-text>
    </v-card>

    <v-card class="mb-2" color="#15263b">
      <v-card-title class="pb-1"> Sisa Utang </v-card-title>

      <v-card-text>
        <h2 v-text="summaries.remaining"></h2>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr class="header">
                <td>Tagihan Awal</td>
                <td v-text="summaries.total" class="text-right"></td>
              </tr>
              <tr>
                <td>Potongan Retur</td>
                <td v-text="summaries.returns" class="text-right"></td>
              </tr>
              <tr class="header">
                <td>Total Tagihan</td>
                <td v-text="summaries.afterReturns" class="text-right"></td>
              </tr>
              <tr>
                <td>Sudah Lunas</td>
                <td v-text="summaries.paymentDone" class="text-right"></td>
              </tr>
              <tr>
                <td>Dibayar Sebagian</td>
                <td v-text="summaries.paymentNotDone" class="text-right"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        filled
        hide-details
        @input="getData"
      ></v-text-field>

      <v-data-table
        :headers="debts.headers"
        :item-class="itemClass"
        :items="debts.data"
        :loading="debts.loading"
        :options.sync="options"
        :server-items-length="debts.count"
        class="elevation-1"
      >
        <template #[`item.actions`]="{ item }">
          <v-btn
            color="light-blue"
            small
            @click="openDetails(item.supplier_id)"
          >
            Details
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import DateRangePicker from '@/components/Pickers/DateRangePicker'

export default {
  name: 'Debts',

  components: { DateRangePicker },

  data() {
    return {
      filter: {
        dateRange: null,
        showList: [
          { text: 'Semua', value: 0 },
          { text: 'Belum Lunas', value: 1 },
          { text: 'Sudah Lunas', value: 2 },
        ],
        show: 0,
      },

      debts: {
        allocated: 0,

        count: 0,

        data: [],

        headers: [
          { text: 'Supplier', value: 'supp_name' },
          { text: 'Total', value: 'total' },
          { text: 'Potongan Retur', value: 'return_alloc' },
          { text: 'Sudah Lunas', value: 'payment_done_alloc' },
          { text: 'Dibayar Sebagian', value: 'payment_not_done_alloc' },
          { text: 'Sisa Utang', value: 'amount' },
          { text: '', sortable: false, value: 'actions' },
        ],

        loading: false,

        paymentDoneAllocated: 0,

        paymentNotDoneAllocated: 0,

        remaining: 0,

        returnAllocated: 0,

        total: 0,

        totalAfterReturns: 0,
      },

      options: {},

      search: '',

      suppliers: {
        data: [],

        loading: false,
      },

      summaries: {
        loading: false,
        total: 0,
        returns: 0,
        afterReturns: 0,
        paymentDone: 0,
        paymentNotDone: 0,
        remaining: 0,
      },
    }
  },

  watch: {
    options: {
      deep: true,

      handler() {
        this.getData()
      },
    },

    'filter.dateRange'() {
      this.getData()
    },
  },

  mounted() {
    this.getSummary()
  },

  methods: {
    getSummary() {
      this.$http
        .get('/suppliers/debts/summary', {
          params: {
            startDate: this.dateRange ? this.date2sql(this.dateRange[0]) : '',
            endDate: this.dateRange ? this.date2sql(this.dateRange[0]) : '',
          },
        })
        .then(({ data }) => {
          this.summaries.total = data.total
          this.summaries.returns = data.returns
          this.summaries.afterReturns = data.after_returns
          this.summaries.paymentDone = data.payments_done
          this.summaries.paymentNotDone = data.payments_not_done
          this.summaries.remaining = data.remaining
        })
    },

    getData() {
      if (!this.filter.dateRange) return

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.debts.data = []
      this.debts.loading = true

      this.$http
        .get('/purchasing/transactions/report/20', {
          params: {
            debt: true,
            show: this.filter.show,
            search: this.search,
            sortBy: sortBy[0] ? sortBy[0] : '',
            sortDesc: sortDesc[0] ? sortDesc[0] : '',
            page: page,
            itemsPerPage: itemsPerPage,
            startDate: this.dateRange ? this.date2sql(this.dateRange[0]) : '',
            endDate: this.dateRange ? this.date2sql(this.dateRange[1]) : '',
          },
        })
        .then((result) => {
          this.debts.allocated = result.data.allocated

          this.debts.count = result.data.count

          this.debts.data = result.data.result

          this.debts.paymentDoneAllocated = result.data.payment_done_allocated

          this.debts.paymentNotDoneAllocated =
            result.data.payment_not_done_allocated

          this.debts.returnAllocated = result.data.return_allocated

          this.debts.remaining = result.data.total

          this.debts.total = result.data.realTotal

          this.debts.totalAfterReturns = result.data.total_after_returns

          this.debts.loading = false
        })
    },

    openDetails(supplierID) {
      this.$router.push(
        '/purchasing/debts/' +
          supplierID +
          '/' +
          this.date2sql(this.filter.dateRange[0]) +
          '/' +
          this.date2sql(this.filter.dateRange[1])
      )
    },

    refresh() {
      this.getSummary()
      this.getData()
    },

    itemClass(item) {
      return this.removeCommas(item.amount) > 0 ? 'bg-red' : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.header td {
  background-color: #333333;
}

::v-deep .bg-red td:first-child {
  background-color: #f44336 !important;
}
</style>
